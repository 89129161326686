<template>
  <div>
    <template class="page" v-if="coupon">
      <div class="page__title">
        {{ getModuleConfig("select_item_page.form_title") }}
      </div>
      <div
        class="page__desc"
        v-html="getModuleConfig('select_item_page.form_desc')"
      ></div>
      <div  class="page__content" v-if="getModuleConfig('select_item_page.content')" v-html="getModuleConfig('select_item_page.content')"></div>

      <div>
        <Loading v-if="showLoading" loading-message="載入中..." />
        <div v-else-if="!showLoading">
          <div class="field">
            <!-- <label class="field__label"> 我的代理商 </label> -->
            <div class="row">
              <div
                class="col-12 px-2"
                v-for="(option, idx) in options"
                :key="idx"
              >
                <SharedRadio
                  v-model="proxy"
                  :id="`proxy-${idx}`"
                  :value="option.value"
                  name="proxy"
                >
                  {{ option.text }}
                </SharedRadio>
              </div>
            </div>
          </div>

          <small>{{ getModuleConfig("select_item_page.form_memo") }}</small>
        </div>
      </div>

      <div
        v-if="
          getModuleConfig('select_item_page.button_text') ||
          getModuleConfig('select_item_page.cancel_button_text')
        "
        class="p-3 fixed-bottom bg-white footer"
      >
        <SharedButton
          :disabled="!proxy"
          @click="confirmSelectItem"
          class="s-btn-bg-primary mb-2"
          >{{ getModuleConfig("select_item_page.button_text") }}</SharedButton
        >

        <MemberButton
          :button-style='`s-btn-outline-primary`'
          :button-text="getModuleConfig('select_item_page.cancel_button_text')"
          :button-url="getModuleConfig('select_item_page.cancel_button_url')"
          :type="getModuleConfig('select_item_page.cancel_button_action')"
        ></MemberButton>

        <!-- <SharedButton variant="outline-dark" class="s-btn-outline-primary">{{
          getModuleConfig("select_item_page.cancel_button_text")
        }}</SharedButton> -->
      </div>
    </template>
    <template v-else>
        <div class="coupon--header">
            <div class="s-px-3 s-text-2xl s-font-bold text-truncate">
                此優惠券不存在
            </div>
            <div v-if="errorMessage">
                <p class="s-mt-2">{{ errorMessage }}</p>
            </div>
        </div>
    </template>
  </div>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import couponMixin from "@/mixins/liff/coupon";
import SharedRadio from "@/components/DynamicForm/Radio.vue";
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import { mapActions } from 'vuex';

export default {
  mixins: [couponMixin],
  components: { Loading, SharedButton, SharedRadio, MemberButton },
  data() {
    return {
      showLoading: false,
      options: [],
      proxy: null,
      coupon: {},
      errorMessage: "",
    };
  },

  computed: {},
  async mounted() {
    await this.fetchCoupon();
  },

  methods: {
    ...mapActions('projAbbottAnd', ['setRedeemData']),
    confirmSelectItem() {
      this.setRedeemData({
        coupon_id: this.$route.params.id,
        item_id: this.proxy,
        item_name: this.options.find((item) => item.value === this.proxy).text,
      });
      this.$router.push({
        name: "LiffProjAbbottAndCouponRedeemConfirm",
      });
    },
    async fetchCoupon() {
      await abbottAndApi.getCouponDetail(
        this.$route.params.orgCode,
        {
          coupon_id: this.$route.params.id
        }).then((res) => {
          this.options = res.data.data.items.map((item) => {
            return {
              text: item.name,
              value: String(item.id),
            }
          })
          if (this.options.length === 0) {
            this.confirmSelectItem()
          }
      }).catch(() => {
        this.coupon = false;
        // this.errorMessage = err.response.data.message;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  &__label {
    font-size: 20px;
    margin-bottom: 8px;
  }
  &__input {
    font-size: 18px;
    background-color: #ffffff;
  }

  &__desc {
    font-size: 16px !important;
  }
}

.row {
  margin: 0 -0.5rem;
}

.coupon {
  display: flex;
  flex-direction: column;
  text-align: center;

  box-sizing: content-box;


  border-radius: 10px;
  background-size: 100% 100% ;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle at 50% 101%, rgba(white,0) 1.5em, white (1.6em));
  background-position: top left, top right;
  filter: drop-shadow(0 2px 8px rgba(black,.3));
  padding-bottom: 1.5em;



  &--header {
    background: var(--liff-header-color);
    color: var(--liff-header-text-color);
    padding: 15px 10px 10px 10px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-top: 1px solid var(--liff-header-text-color);
    border-left: 1px solid var(--liff-header-text-color);
    border-right: 1px solid var(--liff-header-text-color);
  }

  &--barcode {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  &--wrapper{
    overflow-y: scroll;
  }

  &--image {
    margin: 12px;

    img {
      width: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  &--body {
    padding: 0 30px;
    margin: 0 12px 12px 12px;
  }

  &--footer {
    padding: 0 24px 12px;

    .coupon-confirm {
      color: #ea0000;
      font-size: 14px;
    }
  }

  &--copy-panel {
    background: #f2f2f7;
    border-radius: 10px;
    padding: 12px 8px;
    position: relative;
  }

  &--copy-button {
    background: rgba(102, 153, 204, 0.03);
    border: 1px solid rgba(102, 153, 204, 0.6);
    color: #3973ac;
    padding: 4px 16px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    position: absolute;
    right: 8px;
    top: 10px;
  }

  &--line {
    border: 1px dashed #dadada;
  }

  button {
    font-size: 16px;

    &.s-btn-bg-primary {
      background: var(--liff-button-color);
      color: var(--liff-button-text-color);
    }
  }
}
</style>
